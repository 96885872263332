import React, { useContext } from 'react'
import { Layout, SEO } from 'components/common'
import NotFound from 'components/404/NotFound'
import { ThemeContext } from 'providers/ThemeProvider'

export default () => {
  const { theme } = useContext(ThemeContext)
  return (
    <Layout>
      <SEO
        location="/404"
        type="Organization"
        title="404"
        description="404 page not found"
      />
      <NotFound theme={theme} />
    </Layout>
  )
}
