import styled, { css } from 'styled-components'
import { textAlign, padding, background } from 'styled-system'

export const Center = styled.div`
  ${textAlign}
  ${padding}
  ${({ theme }) =>
    theme === 'dark' &&
    css`
      h1 {
        color: #fff;
      }
    `}
`
